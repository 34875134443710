// comment to trigger build
/* eslint-disable camelcase */
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'
import React from 'react'

import {
  Banner,
  Brandy,
  Image,
  LeshenPhoneCTA,
  List,
  ListItem,
  PackageCard,
  Price,
  SplitContent,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'

import { Columns, Dropdown, Typography } from '@leshen/ui'
import { graphql, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const SpanishStateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title, City } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            backgroundColor="dark"
            alignImageToBottom
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                alt={data.heroImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  OBTÉN FRONTIER<sup>&reg;</sup> INTERNET
                </Typography>
                <Typography variant="h4">
                  EN {City?.toUpperCase()}, {State_Name?.toUpperCase()}
                </Typography>
                <Typography variant="p">Fiber 1 Gig por solo</Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Enrutador Wi-Fi Amazon eero incluido<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Sin límites de datos ni cargos por exceso
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Precios de mes a mes sin compromiso anual
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  LLAMA AL
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Compra planes de Internet de Frontier disponibles en {City},{' '}
                  {State_Name}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard label={false} packageData={data.package1} />
              <PackageCard label={false} packageData={data.package2} />
              <PackageCard label={false} packageData={data.package3} />
            </Columns>
          </VariableContent>
          <Banner
            backgroundColor="primary"
            layout="100"
            centerAligned="true"
            mainContent={
              <>
                <Typography variant="h4">
                  Consulta la disponibilidad por ciudad
                </Typography>
                <Typography variant="h5">
                  Encuentra las mejores ofertas de Internet en tu zona: Por
                  favor elige tu ciudad de la lista
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Seleccionar ciudad"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            image={
              <Image
                data={data.motherDaughter.cloudinary[0].gatsbyImageData}
                alt={data.motherDaughter.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.motherDaughterMobile.cloudinary[0].gatsbyImageData}
                alt={data.motherDaughterMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Obtén el servicio de Frontier Internet en {City}
                </Typography>
                <Typography>
                  Con Frontier Internet en {City}, obtendrás acceso a tu mundo
                  digital con un gran valor desde el primer día. Frontier ofrece
                  soporte técnico gratuito las 24 horas, los 7 días de la
                  semana, lo que significa que tienes la asistencia que
                  necesitas cuando la necesitas. Además, cuando eliges Internet
                  de Frontier obtienes datos ilimitados sin compromiso anual,
                  sin tarifa por el router de Wi-Fi y una conexión lista para la
                  red para hacer lo que quieras en línea, cuando quieras, sin
                  límites.
                </Typography>
                <Typography variant="">
                  ¡Puedes mantenerte conectado cuando agregas el teléfono
                  residencial a tu Internet de Frontier! Llama para ver qué
                  ofertas de paquetes de teléfono residencial e Internet de
                  Frontier están disponibles para ti.
                </Typography>
              </>
            }
          />
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h2">
                  Aumenta tu señal Wi-Fi desde $10/mes<sup>♦</sup>
                </Typography>
                <Typography>
                  Elimina los puntos muertos agregando extensores Wi-Fi para
                  todo el hogar a tu plan.
                </Typography>
                <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
              </>
            }
            alignMainContent="Left"
          />
          <SplitContent
            image={
              <Image
                data={data.fatherDaughter.cloudinary[0].gatsbyImageData}
                alt={data.fatherDaughter.cloudinary[0].context.custom.alt}
              />
            }
            mobileImage={
              <Image
                data={data.fatherDaughterMobile.cloudinary[0].gatsbyImageData}
                alt={data.fatherDaughterMobile.cloudinary[0].context.custom.alt}
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Ahorra con los paquetes de Frontier en {(City, State_Name)}
                </Typography>
                <Typography>
                  Un paquete de Frontier es la mejor manera de tener todas tus
                  necesidades de servicios para el hogar en un solo lugar.
                  Combina el poder de Frontier Internet con Frontier Teléfono de
                  Casa y ahorrarás dinero y agilizarás tus facturas cada mes.
                  Además, la tecnología de voz digital ofrece funciones
                  avanzadas para ayudarte a mantenerte mejor conectado con las
                  personas que son importantes para ti. No hay nada mejor que
                  eso. Llama hoy para obtener más información sobre las ofertas
                  de Frontier en {City}.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Perfecto para familias.</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Consolida tus facturas</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Mejor comunicación</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Ahorra dinero cada mes</Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <SplitContent
            image={
              <Image
                data={data.homePhoneImage.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.homePhoneImageMobile.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImageMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Servicio de Teléfono de Casa con Frontier en {City}
                </Typography>
                <Typography variant="p">
                  Con el servicio Frontier Teléfono Residencial, puedes
                  mantenerte en contacto fácilmente con amigos y familiares en
                  todo el país o atender una reunión de trabajo con una calidad
                  de llamada nítida. A diferencia de los teléfonos celulares,
                  que pueden interrumpir tu llamada, podrás tener excelentes
                  conversaciones con amigos y familiares cuando tengas tu plan
                  de telefonía residencial de Frontier en {City}.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Llamadas nacionales ilimitadas</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Evita usar minutos de celular en casa
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Más de 20 útiles funciones de llamadas
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Portal de gestión de llamadas en línea
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

export default SpanishStateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query SpanishStateTemplateQuery(
    $pagePath: String!
    $stateAbbreviation: String!
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierbundlesAoaSpanish(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
          City
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "9f079251-dcfd-5307-993f-a204c2991dab" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "2f67302f-2fd4-55d3-8805-d6330dd58187" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerFrontierbundlesAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    homePhoneImage: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    noContractIcon: contentfulMedia(
      contentful_id: { eq: "UOmJklixDyTIWZJr0SfSv" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    routerIcon: contentfulMedia(
      contentful_id: { eq: "1xbtm0bhAi89Njc98kXFdH" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    speedometerIcon: contentfulMedia(
      contentful_id: { eq: "7AvooAr2FSy6ebTFPqEpbK" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    motherDaughter: contentfulMedia(
      contentful_id: { eq: "49fJTG1haajgsxyzuOqrNZ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    motherDaughterMobile: contentfulMedia(
      contentful_id: { eq: "LijvuuXwucK74bEVTyJXo" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fatherDaughter: contentfulMedia(
      contentful_id: { eq: "143WS7Pjyp06lj3FDYDYXT" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fatherDaughterMobile: contentfulMedia(
      contentful_id: { eq: "2TCp3iv1NvPWYTnPMZdKiw" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuyMobile: contentfulMedia(
      contentful_id: { eq: "7uf13p4haNbePfmzS0lPOL" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "3jjN46RLszRN4O1SgIagy9" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    homePhoneImage: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    homePhoneImageMobile: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-internet-hero-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-internet-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-homephone-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-frontiersecure-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-copperfooter-1-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-wholehomewifi-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-eeroo-es" }
    ) {
      id
      text
      symbol
    }
  }
`
